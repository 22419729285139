// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OurPartners_partnerHeading__EkWRd {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  margin-bottom: 0px
}

.OurPartners_imagesContainer__YfziK {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.OurPartners_partnerImage__PSMoa {
  width: 500px;
  height: 300px;
  object-fit: contain;
}


@media screen and (max-width: 768px) {
  .OurPartners_partnerImage__PSMoa {
    width: 300px;
    height: 200px;
  }
}

@media screen and (max-width: 576px) {
  .OurPartners_partnerImage__PSMoa {
    width: 200px;
    height: 150px;
  }
}


`, "",{"version":3,"sources":["webpack://./src/Components/OurPartners/OurPartners.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;;AAGA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".partnerHeading {\n  font-size: 40px;\n  font-weight: 700;\n  margin-bottom: 2rem;\n  text-align: center;\n  margin-bottom: 0px\n}\n\n.imagesContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 2rem;\n}\n\n.partnerImage {\n  width: 500px;\n  height: 300px;\n  object-fit: contain;\n}\n\n\n@media screen and (max-width: 768px) {\n  .partnerImage {\n    width: 300px;\n    height: 200px;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .partnerImage {\n    width: 200px;\n    height: 150px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partnerHeading": `OurPartners_partnerHeading__EkWRd`,
	"imagesContainer": `OurPartners_imagesContainer__YfziK`,
	"partnerImage": `OurPartners_partnerImage__PSMoa`
};
export default ___CSS_LOADER_EXPORT___;
