// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OurFinancialPartner_outerContainer__NfBn0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 5rem; */
}
.OurFinancialPartner_financialHeading__2e16p {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  margin-bottom: 70px;
}

.OurFinancialPartner_advisorCardContainer__lwIHm {
  display: flex;
  flex-direction: row;
  gap: 200px;
}

@media (max-width: 600px) {
  .OurFinancialPartner_advisorCardContainer__lwIHm {
    flex-direction: column;
    gap: 100px;
  }
}

.OurFinancialPartner_cardOuter__15W2p {
  position: relative;
  width: 200px;
  height: 330px;
}

.OurFinancialPartner_rectangle__-ZZ5e {
  position: absolute;
  top: 20px;
  left: -45px;
  width: 224.35px;
  height: 67.37px;
  background-color: red;
  border-radius: 70px;
  z-index: -1;
  rotate: -32.35deg;
}

.OurFinancialPartner_image__ImoU8 {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  /* margin-top: 20px; */
}

.OurFinancialPartner_cardHeading__hT4mX {
  margin-top: 20px;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

.OurFinancialPartner_cardSubHeading__AANTW {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #494949;
}
`, "",{"version":3,"sources":["webpack://./src/Components/OurFinancialPartner/OurFinancialPartner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE;IACE,sBAAsB;IACtB,UAAU;EACZ;AACF;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".outerContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  /* margin-top: 5rem; */\n}\n.financialHeading {\n  font-size: 40px;\n  font-weight: 700;\n  margin-bottom: 2rem;\n  text-align: center;\n  margin-bottom: 70px;\n}\n\n.advisorCardContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 200px;\n}\n\n@media (max-width: 600px) {\n  .advisorCardContainer {\n    flex-direction: column;\n    gap: 100px;\n  }\n}\n\n.cardOuter {\n  position: relative;\n  width: 200px;\n  height: 330px;\n}\n\n.rectangle {\n  position: absolute;\n  top: 20px;\n  left: -45px;\n  width: 224.35px;\n  height: 67.37px;\n  background-color: red;\n  border-radius: 70px;\n  z-index: -1;\n  rotate: -32.35deg;\n}\n\n.image {\n  width: 180px;\n  height: 180px;\n  border-radius: 10px;\n  /* margin-top: 20px; */\n}\n\n.cardHeading {\n  margin-top: 20px;\n  font-size: 28px;\n  font-weight: 500;\n  line-height: 32px;\n  text-align: center;\n}\n\n.cardSubHeading {\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: center;\n  color: #494949;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `OurFinancialPartner_outerContainer__NfBn0`,
	"financialHeading": `OurFinancialPartner_financialHeading__2e16p`,
	"advisorCardContainer": `OurFinancialPartner_advisorCardContainer__lwIHm`,
	"cardOuter": `OurFinancialPartner_cardOuter__15W2p`,
	"rectangle": `OurFinancialPartner_rectangle__-ZZ5e`,
	"image": `OurFinancialPartner_image__ImoU8`,
	"cardHeading": `OurFinancialPartner_cardHeading__hT4mX`,
	"cardSubHeading": `OurFinancialPartner_cardSubHeading__AANTW`
};
export default ___CSS_LOADER_EXPORT___;
